//Banner Section
#app {
  position: relative;
  animation: fadeIn 0.4s ease-in-out forwards;
  -webkit-animation: fadeIn 0.4s ease-in-out forwards;
}

.ezBanner {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(34, 139, 34, 0.8), rgba(34, 139, 34, 0.8)),
      url('./images/homeBg.png') no-repeat center/cover;
    clip-path: polygon(0 0%, 100% 0%, 100% 75%, 0% 100%);
  }
  .bannerUl li {
    position: relative;
    padding-left: 25px;
    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 1px;
      width: 8px;
      height: 8px;
      background: #fff;
      @include TransForm(rotate(135deg));
    }
  }
}

.ezUserManualPage {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(rgba(34, 139, 34, 0.8), rgba(34, 139, 34, 0.8)),
      url('./images/homeBg.png') no-repeat center/cover;
    clip-path: polygon(0 0%, 100% 0%, 100% 70%, 0% 100%);
  }
}

section.wrapper {
  @include Flex(space-between, flex-end);
  height: 550px;
  transition: background-image 0.4s ease-in-out;
  flex-direction: column;
  background-size: auto 550px; // Adjust width according to the height of 500px
  background-position: center; // Horizontally center align the image
  background-repeat: no-repeat; // Prevent image repetition
  @include Media(1279) {
    height: 450px;
    background-size: auto 450px;
  }
  @include Media(767) {
    height: 300px;
    background-size: auto 300px;
  }
}

.selectors {
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .selector {
    position: relative;
    @include Flex(center, center);
    width: 18px;
    height: 18px;
    @include BorRadius(50%);
    margin: 2px 0;
    cursor: pointer;
    transition: background 0.3s ease-in-out, opacity 0.3s ease-in-out;
    &.active {
      background: green;
      span {
        background: #fff;
      }
    }
    span {
      @include BorRadius(50%);
      background: rgba(255, 255, 255, 0.6);
      width: 8px;
      height: 8px;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//Filter Section
.ezBaseCard span {
  position: relative;
  width: 100%;
  display: block;
  &::before {
    content: '';
    position: absolute;
    top: 46%;
    left: 25px;
    width: 25%;
    height: 1px;
    background: #228b22;
  }
}

.ezNavigateContent {
  padding: 0 0 100px 100px;
  margin-bottom: -100px;
  @include Media(767) {
    padding: 0;
    margin-bottom: 0;
  }
  .navigateImg {
    width: 60%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

//Road Map
.roadMapBg {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    z-index: -1;
    background: url('./images/roadMapLinesBg.png') no-repeat bottom/cover;
    @include TransForm(rotate(-180deg));
  }
  .roadMapInnerBg {
    position: relative;
    clip-path: polygon(0 25%, 100% 5%, 100% 75%, 0% 95%);
    img {
      @include TransForm(scale(1.3) translateY(20px));
    }
  }
}
